import './node_modules/bootstrap/dist/css/bootstrap.css';

const addScript = (url) => {
  const script = document.createElement('script');
  script.src = url;
  script.async = true;
  script.defer = true;
  document.body.appendChild(script);
};

export const onClientEntry = () => {
  window.onload = () => {
    /* eslint-disable-next-line no-underscore-dangle */
    window._wq = window._wq || [];
    // eslint-disable-next-line no-undef
    _wq.push({
      id: '9sd14w81s1',
      options: {
        videoFoam: true,
      },
    });
    addScript('https://fast.wistia.com/embed/medias/9sd14w81s1.jsonp');
    addScript('https://fast.wistia.com/assets/external/E-v1.js');
  };
};
